@use "@scss/colors" as colors;
@use "@scss/mixins" as mixins;
@use "@scss/variables" as variables;
@use "@scss/responsives" as responsives;

.container {
  width: variables.$web-width;
  padding-top: 5rem;

  @include responsives.resolution__laptop__l {
    width: 100%;
    padding-top: 0;
  }

  h3 {
    font-weight: bold;
    margin-top: 5rem;
    text-transform: uppercase;
    width: 100%;
    text-align: center;

    @include responsives.resolution__laptop__l {
      padding: 0 1.5rem;
      margin-bottom: 1rem;
    }
  }

  .topContainer {
    @include mixins.flex_container(row, space-between, center);
    width: 100%;
    margin: 1.5rem 0;

    @include responsives.resolution__laptop__l {
      padding: 0 1.5rem;
    }

    @include responsives.resolution__tablet {
      @include mixins.flex_container(column, center, center);
      margin: 0.5rem 0;
      margin-bottom: 1.5rem;
    }

    .dateContainer {
      @include mixins.flex_container(row, flex-start, center);

      @include responsives.resolution__tablet {
        margin-bottom: 1.5rem;
      }

      p {
        color: colors.$color-gray-5;
        margin: 0;
      }
    }
  }

  .documentsContainer {
    @include mixins.flex_container(column, center, flex-start);
    margin: 0 1rem;

    hr {
      color: black;
      width: 100%;
    }

    .filesTitle {
      color: colors.$color-gray-5;
      margin: 0;
      font-size: larger;
      font-weight: bolder;
    }

    .documents {
      margin-top: 0.5rem;

      a {
        color: colors.$color-gray-5;
        margin: 1rem 1rem 0 0;
      }

      span {
        margin-left: 0.5rem;
        font-size: 0.75rem;
      }
    }
  }
}
