@use "@scss/mixins" as mixins;
@use "@scss/responsives" as responsives;
@use "@scss/colors" as colors;

.containerPiece {
  position: relative;

  .imageContainer {
    @include mixins.flex_container(row, center, center);
    width: 35rem;
    height: 35rem;
    overflow: hidden;
    cursor: pointer;

    @include responsives.resolution__laptop__s {
      width: 28rem;
      height: 28rem;
    }

    @include responsives.resolution__tablet {
      width: 20rem;
      height: 20rem;
    }

    @include responsives.resolution__mobile {
      width: 15rem;
      height: 15rem;
    }

    .artPiece {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .linkPiece {
    @include mixins.flex_container(row, center, center);
    position: absolute;
    transform: translate(0%, 0%);
    bottom: 0%;
    right: 0%;
    text-align: center;
    height: 2.5rem;
    width: 7.5rem;
    background-color: colors.$brand-dark;
    color: colors.$color-white;

    @include responsives.resolution__tablet {
      font-size: 0.8rem;
      height: 2rem;
      width: 7rem;
    }
  }
}
