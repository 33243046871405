@use "@scss/colors" as colors;
@use "@scss/mixins" as mixins;
@use "@scss/variables" as variables;
@use "@scss/responsives" as responsives;

.container {
  @include mixins.flex_container(column, space-between, center);
  width: variables.$web-width;

  @include responsives.resolution__laptop__m {
    width: 100%;
  }

  .desktop {
    @include responsives.resolution__laptop__m {
      display: none;
    }
  }

  .mobile {
    display: none;

    @include responsives.resolution__laptop__m {
      display: block;
    }
  }

  .postPreview {
    position: relative;
    width: 100%;

    h5 {
      text-align: center;
      position: absolute;
      transform: translate(0%, 0%);
      top: 0%;
      left: 0%;
      color: colors.$color-white;
      background-color: colors.$color-black-translucid;
      padding: 1.2rem;
      font-size: 1.3rem;

      @include responsives.resolution__laptop__m {
        font-size: 1.1rem;
      }

      @include responsives.resolution__laptop__s {
        font-size: 0.9rem;
      }
    }

    img {
      width: 100%;
    }
  }

  .discoverButton {
    margin-top: 4rem;

    @include responsives.resolution__laptop__s {
      margin-top: 2rem;
    }
  }
}
