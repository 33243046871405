$height-header: 6.25rem;
$height-header-mobile: 5rem;
$min-height-main: calc(100vh - #{$height-header});
$min-height-main-mobile: calc(100vh - #{$height-header-mobile});

$link-size-desktop: 1rem;
$link-size-mobile: 0.75rem;

$h1-size: 5rem;
$h2-size: 3.75rem;
$h3-size: 2.5rem;
$h4-size: 1.75rem;

$h1-size-mobile: 3rem;
$h2-size-mobile: 1.7rem;
$h3-size-mobile: 1.2rem;
$h4-size-mobile: 1rem;

$web-padding: 7rem;
$web-padding-mobile: 2rem;
$web-width: 75rem;
