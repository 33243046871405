@use "@scss/colors" as colors;
@use "@scss/mixins" as mixins;
@use "@scss/responsives" as responsives;

.anualReport {
  @include mixins.flex_container(column, flex-end, center);
  margin-bottom: 6rem;
  position: relative;

  @include responsives.resolution__laptop__s {
    margin: 3rem 0;
  }

  img {
    width: 100%;
  }

  a {
    color: colors.$color-simil-black;
  }

  button {
    margin-bottom: 2rem;
    position: absolute;
  }

  @include responsives.resolution__mobile {
    button {
      width: 90%;
      font-size: 0.67rem;
    }
  }
}
