@use "@scss/variables" as variables;
@use "@scss/mixins" as mixins;
@use "@scss/responsives" as responsives;

.container {
  @include mixins.flex_container(row, center, flex-start);
  width: 100%;

  > .content {
    @include mixins.flex_container(row, center, flex-start);
    padding-top: variables.$height-header;
    width: variables.$web-width;
    min-height: variables.$min-height-main;
    padding-bottom: variables.$web-padding;

    @include responsives.resolution__laptop__l {
      min-height: variables.$min-height-main-mobile;
      max-width: 100%;
      padding-bottom: variables.$web-padding-mobile;
    }

    @include responsives.resolution__laptop__m {
      padding-top: variables.$height-header-mobile;
    }
  }
}
