@use "@scss/mixins" as mixins;
@use "@scss/colors" as colors;
@use "@scss/responsives" as responsives;

.container {
  @include mixins.flex_container(column, space-between, center);
  width: 100%;

  .introText {
    width: 100%;
    text-align: center;
    margin-top: 2.5rem;
    margin-bottom: -4rem;
    font-size: 1.3rem;

    @include responsives.resolution__laptop__l {
      margin-bottom: 0;
      padding: 0 1.5rem;
    }

    @include responsives.resolution__laptop__s {
      font-size: 1rem;
    }

    span {
      color: colors.$color-gray-6;
      font-weight: bold;
    }

    color: colors.$color-gray-5;
  }
}
