@use "@scss/colors" as colors;
@use "@scss/mixins" as mixins;
@use "@scss/responsives" as responsives;

.container {
  @include responsives.resolution__laptop__m {
    @include mixins.flex_container(column, flex-start, center);
  }

  display: none;
  position: fixed;
  background-color: colors.$brand-dark;
  width: 100%;
  z-index: 999;

  .buttons {
    @include mixins.flex_container(row, space-between, center);
    width: 100%;
    height: 5rem;
    padding: 1rem 0 1rem 1.5rem;

    .main {
      @include mixins.flex_container(row, flex-start, center);

      button {
        background-color: colors.$color-transparent;
        border: none;
        width: 2.625rem;
        margin-right: 1rem;
      }

      a {
        .brandLogo {
          margin-right: 1rem;
          max-width: 5.5rem;
        }
      }
    }

    > button {
      margin-right: 1.5rem;
    }
  }
}
