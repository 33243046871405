$color-white: #ffffff;
$color-simil-white: #f5f5f7;
$color-black-translucid: #0000009a;
$color-simil-black: #141414;
$color-orange: #ff8643;
$color-gray-6: #434343;
$color-gray-5: #727272;
$color-gray-4: #d6d6d6;
$color-transparent: #00000000;
$shadow: rgb(16 36 94 / 20%);
$brand-dark: #141414;
