@use "@scss/colors" as colors;
@use "@scss/mixins" as mixins;
@use "@scss/variables" as variables;
@use "@scss/responsives" as responsives;

.container {
  @include mixins.flex_container(column, space-between, center);
  width: variables.$web-width;

  @include responsives.resolution__laptop__m {
    width: 100%;
  }
}
