@use "@scss/mixins" as mixins;
@use "@scss/responsives" as responsives;

.section {
  width: calc(100% + 3%);
  text-align: center;

  .partnerGrid {
    @include mixins.flex_container(row, center, center);
    width: 100%;
    flex-wrap: wrap;

    @include responsives.resolution__laptop__l {
      padding: 0 1.5rem;
    }
  }

  .partnerGrid {
    .partnerItem {
      @include mixins.flex_container(column, center, center);
      width: 21%;
      margin: 0 2%;

      .imageContainer {
        height: 100%;
        width: 100%;

        img {
          width: 100%;
        }
      }

      .imageName {
        margin-top: 1rem;

        @include responsives.resolution__tablet {
          margin-top: 0.5rem;
          font-size: 0.7rem;
        }
      }
    }
  }
}
