@use "@scss/mixins" as mixins;
@use "@scss/variables" as variables;
@use "@scss/responsives" as responsives;

.container {
  @include mixins.flex_container(column, space-between, center);
  width: variables.$web-width;

  @include responsives.resolution__laptop__m {
    width: 100%;
  }

  > div {
    width: 100% !important;
    height: 37.5rem !important;

    @include responsives.resolution__laptop__m {
      height: 20rem !important;
    }
  }
}
