@use "@scss/colors" as colors;
@use "@scss/mixins" as mixins;
@use "@scss/variables" as variables;
@use "@scss/responsives" as responsives;

.container {
  @include mixins.flex_container(column, space-between, center);
  width: variables.$web-width;
  padding: 0 1.5rem;

  @include responsives.resolution__laptop__l {
    width: 100%;
  }

  .titleContainer {
    @include mixins.flex_container(row, flex-start, center);
    margin-top: 7rem;
    position: relative;
    width: 100%;
    margin-bottom: 2rem;

    @include responsives.resolution__laptop__l {
      margin-top: 4rem;
      margin-bottom: 0;
    }

    img {
      width: 100%;
    }

    h2 {
      width: 50%;
      padding: 0 2.5rem;
      position: absolute;
      color: colors.$color-white;
    }
  }

  .content {
    @include mixins.flex_container(row, space-between, flex-start);
    width: 100%;
    height: 35rem;
    margin: 2rem 0;

    @include responsives.resolution__laptop__l {
      @include mixins.flex_container(row, center, center);
      margin-bottom: 4rem;
    }

    @include responsives.resolution__laptop__m {
      @include mixins.flex_container(column, center, center);
      height: auto;
    }

    .exchangeContainer {
      width: 37rem;
      height: 100%;

      @include responsives.resolution__laptop__l {
        @include mixins.flex_container(column, center, center);
        height: 34rem;
      }

      @include responsives.resolution__laptop__s {
        max-width: 42.5rem;
        width: 100%;
      }
    }

    .userData {
      @include mixins.flex_container(column, space-between, center);
      height: 100%;
      width: 37rem;

      @include responsives.resolution__laptop__l {
        height: 30rem;
        margin-bottom: 4rem;
      }

      @include responsives.resolution__laptop__m {
        height: auto;
      }

      @include responsives.resolution__laptop__s {
        width: 100%;
        margin-bottom: 1rem;
      }

      .accountBalance {
        @include mixins.flex_container(column, center, flex-start);
        background-image: url("../../assets/images/utils/logo-banner.png");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 1rem;
        width: 100%;

        @include responsives.resolution__mobile {
          padding: 0.5rem;
        }

        h4 {
          color: colors.$color-white;
          font-size: 1.5rem !important;
          margin-bottom: 0.25rem !important;

          @include responsives.resolution__mobile {
            font-size: 0.75rem !important;
          }
        }
      }
    }
  }

  .noResults {
    margin-top: 1rem;
    color: colors.$brand-dark;
    font-size: 1.5rem;
  }
}
