@use "@scss/colors" as colors;
@use "@scss/mixins" as mixins;
@use "@scss/responsives" as responsives;

.container {
  width: 100%;

  > div {
    a:nth-child(2),
    a:nth-child(3) {
      transform: translate(-50%, -50%);
      top: 50%;
      border-radius: 3rem;
      background-color: colors.$brand-dark;
      height: 5rem;
      width: 5rem;
      opacity: 10;

      @include responsives.resolution__laptop__m {
        @include mixins.flex_container(row, center, center);
        top: 45%;
        height: 3rem;
        width: 3rem;

        span {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }

    a:nth-child(2) {
      left: 7.5%;
    }

    a:nth-child(3) {
      left: 92.5%;
    }
  }
}
