@use "@scss/colors" as colors;
@use "@scss/mixins" as mixins;
@use "@scss/variables" as variables;
@use "@scss/responsives" as responsives;

.container {
  @include mixins.flex_container(column, space-between, center);
  width: variables.$web-width;
  overflow-x: hidden;
  margin-top: -2rem;

  @include responsives.resolution__laptop__m {
    width: 100%;
  }

  .controls {
    @include mixins.flex_container(row, center, center);
    margin-top: -1rem;
    margin-bottom: 3rem;
    z-index: 10;
    background: colors.$color-white;
    transition: opacity ease-in-out 0.2s;
    box-shadow: 0 1rem 2.5rem 0 colors.$shadow;
    border-radius: 0.25rem;
    height: 3rem;

    p {
      margin: 0;
      font-size: 1rem;
    }

    .disabled {
      color: colors.$color-gray-4;
      cursor: default;
    }

    button {
      background-color: colors.$color-transparent;
      color: colors.$brand-dark;
      border: none;
      margin: 0.2rem;
      width: 2rem;
      height: 2rem;
    }
  }
}
