@use "@scss/colors" as colors;
@use "@scss/mixins" as mixins;
@use "@scss/variables" as variables;
@use "@scss/fonts" as fonts;
@use "@scss/responsives" as responsives;

.container {
  @include mixins.flex_container(column, space-between, center);
  width: 100%;

  .grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -1.875rem;
    width: calc(variables.$web-width - 8rem);

    @include responsives.resolution__laptop__m {
      width: 100%;
    }
  }

  .gridColumnMobile {
    display: none;

    @include responsives.resolution__laptop__m {
      display: block;
    }
  }

  .gridColumnDesktop {
    display: block;

    @include responsives.resolution__laptop__m {
      display: none;
    }
  }

  .gridColumnMobile,
  .gridColumnDesktop {
    padding-left: 1.875rem;
    background-clip: padding-box;

    .postContainer {
      h5 {
        font-family: fonts.$font-oswald;
        font-size: 1.5rem;
        color: colors.$brand-dark;

        @include responsives.resolution__laptop__m {
          font-size: 1rem;
        }
      }

      img {
        width: 100%;
        margin-bottom: 1rem;
      }

      p {
        color: colors.$brand-dark;

        @include responsives.resolution__laptop__m {
          font-size: 0.7rem;
        }
      }

      .readTime {
        color: colors.$color-gray-5;
      }
    }
  }
}
