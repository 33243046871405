@use "@scss/responsives" as responsives;

.container {
  margin-top: 3rem;
  width: 100%;
  height: 35rem;

  @include responsives.resolution__laptop__s {
    height: 28rem;
    margin-top: 1rem;
  }

  @include responsives.resolution__tablet {
    height: 20rem;
  }

  @include responsives.resolution__mobile {
    height: 15rem;
  }
}
