@use "@scss/colors" as colors;
@use "@scss/mixins" as mixins;
@use "@scss/variables" as variables;
@use "@scss/fonts" as fonts;
@use "@scss/responsives" as responsives;

.container {
  @include mixins.flex_container(column, center, center);
  width: 100%;
  height: 20rem;
  background-color: colors.$color-simil-black;
  padding-top: 1rem;

  @include responsives.resolution__tablet {
    height: auto;
    padding: 2rem 0;
  }

  p {
    color: colors.$color-white;
  }

  .maxWithContainer {
    @include mixins.flex_container(row, space-between, center);
    width: 100%;
    height: 100%;
    max-width: 120rem;
    padding: 0 3rem;

    @include responsives.resolution__laptop__m {
      max-width: auto;
    }

    @include responsives.resolution__tablet {
      @include mixins.flex_container(column, flex-start, center);
    }

    .leftSection {
      @include mixins.flex_container(column, center, flex-start);
      width: 50%;
      height: 100%;

      @include responsives.resolution__tablet {
        @include mixins.flex_container(column, center, center);
        width: auto;
      }

      > img {
        width: 11rem;
        margin-bottom: 1rem;

        @include responsives.resolution__tablet {
          width: 8rem;
        }
      }

      p {
        margin-bottom: 1rem;
        font-family: fonts.$font-oswald-light;

        @include responsives.resolution__tablet {
          text-align: center;
          font-size: 0.8rem;
        }
      }

      .socialMedia {
        @include mixins.flex_container(row, flex-start, flex-start);
        flex-wrap: wrap;
        width: 100%;
        margin-left: -1rem;

        @include responsives.resolution__tablet {
          @include mixins.flex_container(row, center, center);
          margin-left: 0;
        }

        a {
          padding: 0rem 1rem;
        }

        @include responsives.resolution__mobile {
          a {
            padding: 0.5rem;
          }
        }
      }

      .sign {
        margin-top: 1.5rem;
        font-size: 0.75rem;

        @include responsives.resolution__tablet {
          margin-top: 0.5rem;
          margin-bottom: 0;
          font-size: 0.65rem;
        }

        p {
          margin: 0rem;
        }

        img {
          width: 5.313rem;
          margin-left: 0.5rem;

          @include responsives.resolution__tablet {
            width: 4.8rem;
          }
        }
      }
    }

    .links {
      @include mixins.flex_container(row, space-between, center);
      width: 23rem;

      @include responsives.resolution__laptop__m {
        width: 20rem;
      }

      @include responsives.resolution__tablet {
        @include mixins.flex_container(row, space-evenly, flex-start);
        width: 100%;
      }

      .navBar {
        @include mixins.flex_container(column, flex-start, flex-start);
        height: 11rem;

        @include responsives.resolution__tablet {
          @include mixins.flex_container(column, flex-start, center);
        }

        @include responsives.resolution__tablet {
          height: auto;
          padding: 1rem 0;
          width: 50%;
        }

        p {
          margin-bottom: 0.5rem;
          font-family: fonts.$font-oswald;
        }

        a {
          margin: 0.25rem 0;
          font-size: variables.$link-size-desktop;
          color: colors.$color-gray-5;
        }
      }
    }
  }
}
