@use "@scss/colors" as colors;
@use "@scss/mixins" as mixins;
@use "@scss/variables" as variables;
@use "@scss/responsives" as responsives;

.container {
  @include mixins.flex_container(row, flex-start, center);
  width: 100%;
  padding-top: variables.$web-padding;
  padding-bottom: 4rem;

  @include responsives.resolution__laptop__l {
    padding: variables.$web-padding-mobile 1.5rem 2rem 1.5rem;
  }

  h1,
  h2,
  h3 {
    white-space: nowrap;
    font-weight: bold;
    text-align: start;
  }

  .lineContainer {
    @include mixins.flex_container(row, center, center);
    height: 100%;
    width: 100%;
    padding-left: 2rem;

    hr {
      opacity: initial;
      height: 0.1rem !important;
      background-color: colors.$color-gray-4;
      width: 100%;
    }
  }
}

.fixedWidth {
  width: variables.$web-width;

  @include responsives.resolution__laptop__l {
    width: 100%;
    padding-left: 1.5rem;
  }
}

.center {
  @include mixins.flex_container(row, center, center);
}
