@use "@scss/colors" as colors;
@use "@scss/mixins" as mixins;

.primary {
  @include mixins.btn_primary(colors.$color-white, colors.$brand-dark);
}

.secondary {
  @include mixins.btn_primary(colors.$brand-dark, colors.$color-white);
}

.link {
  @include mixins.btn_link(colors.$color-white);
}
