@use "@scss/mixins" as mixins;
@use "@scss/responsives" as responsives;

.goToTheVault {
  @include mixins.flex_container(row, space-between, center);
  width: 100%;

  a {
    margin-top: 7rem;
    margin-bottom: 4rem;
    margin-left: 2rem;

    @include responsives.resolution__laptop__l {
      margin: 2rem 2rem 2rem 0;
    }
  }
}
