@use "@scss/colors" as colors;
@use "@scss/mixins" as mixins;
@use "@scss/variables" as variables;
@use "@scss/responsives" as responsives;

.container {
  @include mixins.flex_container(column, space-between, center);
  width: variables.$web-width;

  @include responsives.resolution__laptop__m {
    width: 100%;
  }

  .gridContainer {
    @include mixins.flex_container(row, center, center);
    width: 100%;

    .currencyDataContainer {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 3.3rem;
      column-gap: 5%;

      @include responsives.resolution__laptop__m {
        row-gap: 2rem;
        column-gap: 8%;
        padding-left: 1.5rem;
      }

      @include responsives.resolution__laptop__s {
        column-gap: 5%;
      }

      @include responsives.resolution__tablet {
        width: 29.375rem;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 4%;
      }

      @include responsives.resolution__mobile {
        width: 23.75rem;
        column-gap: 0;
        row-gap: 1rem;
      }

      .currencyData {
        background-color: colors.$brand-dark;
        overflow: hidden;
        width: 22.5rem;
        height: 22.5rem;
        position: relative;

        @include responsives.resolution__laptop__m {
          width: 15rem;
          height: 15rem;
        }

        @include responsives.resolution__laptop__s {
          width: 13rem;
          height: 13rem;
        }

        @include responsives.resolution__tablet {
          width: 12rem;
          height: 12rem;
        }

        @include responsives.resolution__mobile {
          width: 10rem;
          height: 10rem;
        }

        .textContainer {
          @include mixins.flex_container(column, center, center);
          height: 100%;
          width: 100%;
          position: absolute;
          padding: 0 1.3rem;

          p,
          h3 {
            color: colors.$color-white;
            text-align: center;
            text-transform: uppercase;
          }

          p {
            font-size: 1.5rem;

            @include responsives.resolution__laptop__m {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}
