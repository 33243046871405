@use "@scss/colors" as colors;
@use "@scss/mixins" as mixins;
@use "@scss/variables" as variables;
@use "@scss/responsives" as responsives;

.container {
  @include mixins.flex_container(row, center, center);
  width: 100%;
  height: variables.$height-header;
  background-color: colors.$color-simil-black;
  padding: 0 3rem;
  position: fixed;
  z-index: 100;

  @include responsives.resolution__laptop__m {
    display: none;
  }

  .content {
    @include mixins.flex_container(row, space-between, center);
    width: 100%;
    max-width: 120rem;

    .brandLogo {
      margin-right: 3rem;
      max-width: 8rem;
    }

    > div {
      @include mixins.flex_container(row, flex-end, center);
      width: 100%;
    }
  }
}
