@use "@scss/colors" as colors;
@use "@scss/mixins" as mixins;
@use "@scss/responsives" as responsives;

$loader-size: 15rem;
$loader-size-mobile: 8rem;

.container {
  @include mixins.flex_container(row, center, center);
  margin: 5rem 0;
  width: 100%;

  .loader {
    @include mixins.flex_container(row, center, center);
    position: relative;
    width: $loader-size;
    height: $loader-size;

    @include responsives.resolution__laptop__s {
      width: $loader-size-mobile;
      height: $loader-size-mobile;
    }

    img {
      width: 100%;
    }

    .spinner {
      position: absolute;
      border: calc($loader-size / 10) solid colors.$color-transparent;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border-left-color: colors.$brand-dark;
      animation: spin 1s ease infinite;

      @include responsives.resolution__laptop__s {
        border: calc($loader-size-mobile / 10) solid colors.$color-transparent;
        border-left-color: colors.$brand-dark;
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
