@use "@scss/colors" as colors;
@use "@scss/mixins" as mixins;
@use "@scss/variables" as variables;
@use "@scss/responsives" as responsives;

.container {
  width: 100%;
  max-width: 62.5rem;
  margin-right: 5%;

  @include responsives.resolution__laptop__m {
    max-width: none;
    height: 100vh;
    margin-top: 5rem;
    margin-right: 0;
    background-color: colors.$color-white;
    position: fixed;
    z-index: 999;
  }

  ul {
    @include mixins.flex_container(row, space-between, center);
    list-style: none;
    width: 100%;
    margin-bottom: 0;

    @include responsives.resolution__laptop__m {
      @include mixins.flex_container(column, center, center);
      padding: 0 1rem;
    }

    @include responsives.resolution__laptop__m {
      li {
        width: 100%;
        padding: 1.5rem 0;
        text-align: center;
        border-bottom: 0.025rem solid colors.$color-gray-4;
      }
    }

    a {
      font-size: variables.$link-size-desktop;
      color: colors.$color-white;

      @include responsives.resolution__laptop__m {
        color: colors.$brand-dark;
      }
    }
  }
}
