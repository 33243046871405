@use "@scss/colors" as colors;
@use "@scss/mixins" as mixins;
@use "@scss/variables" as variables;
@use "@scss/fonts" as fonts;
@use "@scss/responsives" as responsives;

.arrow {
  position: absolute;
  background-repeat: no-repeat !important;
  background-position: 50% !important;
  background-size: 1.5rem 1.5rem !important;
  border-radius: 3rem;
  background-color: colors.$brand-dark !important;
  display: block !important;
  height: 3rem !important;
  width: 3rem !important;
  transform: translate(0%, 100%) !important;
  top: 20% !important;
  z-index: 100;

  @include responsives.resolution__laptop__m {
    top: 18% !important;
  }

  @include responsives.resolution__tablet {
    top: 15% !important;
    height: 2rem !important;
    width: 2rem !important;
    background-size: 1rem 1rem !important;
  }

  @include responsives.resolution__mobile {
    top: 22% !important;
  }

  &::before {
    display: none;
  }
}

.leftArrow {
  left: -6% !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") !important;

  @include responsives.resolution__laptop__m {
    left: 6% !important;
  }
}

.rightArrow {
  right: -6% !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;

  @include responsives.resolution__laptop__m {
    right: 6% !important;
  }
}

.container {
  width: calc(variables.$web-width - 8rem);

  @include responsives.resolution__laptop__m {
    width: 100%;
  }

  .postContainer {
    padding: 0 1rem;

    h5 {
      font-family: fonts.$font-oswald;
      font-size: 1.5rem;
      color: colors.$brand-dark;

      @include responsives.resolution__laptop__m {
        font-size: 1rem;
      }
    }

    .imageContainer {
      @include mixins.flex_container(row, center, center);
      height: 18rem;
      overflow: hidden;
      margin-bottom: 1rem;

      @include responsives.resolution__tablet {
        height: 11rem;
      }

      @include responsives.resolution__mobile {
        height: 13rem;
      }

      img {
        width: 100%;
      }
    }

    p {
      color: colors.$brand-dark;

      @include responsives.resolution__laptop__m {
        font-size: 0.7rem;
      }
    }

    .readTime {
      color: colors.$color-gray-5;
    }
  }
}
