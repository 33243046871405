@use "@scss/responsives" as responsives;

.container {
  .indexImage {
    max-width: 26rem;

    @include responsives.resolution__mobile {
      width: 100%;
      max-width: 100%;
    }
  }

  @include responsives.resolution__laptop__m {
    padding: 1rem 0;
  }
}
