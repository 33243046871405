@use "@scss/colors" as colors;
@use "@scss/mixins" as mixins;
@use "@scss/responsives" as responsives;

.container {
  width: 100%;
  position: relative;

  @include responsives.resolution__laptop__s {
    @include mixins.flex_container(column, flex-start, flex-start);
    margin-bottom: 9.8rem;
  }

  .imageContainer {
    @include mixins.flex_container(row, center, center);
    width: 100%;
    height: 42.188rem;
    overflow: hidden;

    @include responsives.resolution__laptop__m {
      height: 34rem;
    }

    @include responsives.resolution__laptop__s {
      height: 25rem;
    }

    @include responsives.resolution__tablet {
      height: 17rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .artInformationContainer {
    position: absolute;
    width: 30rem;
    bottom: 0;
    right: 0;
    transform: translate(-10%, -20%);
    padding: 1.5rem;
    background-color: colors.$color-black-translucid;

    @include responsives.resolution__laptop__s {
      @include mixins.flex_container(column, flex-end, flex-start);
      width: 100%;
      padding: 1rem;
      bottom: 0;
      left: 0;
      transform: translate(0%, 100.5%);
      background-color: colors.$brand-dark;
    }

    .title {
      margin-bottom: 1.5rem;
    }

    .title,
    .author {
      color: colors.$color-white;

      @include responsives.resolution__laptop__m {
        margin-bottom: 0.3rem;
      }
    }

    .author,
    .date {
      font-size: 1rem;
    }

    .date {
      color: colors.$color-white;

      @include responsives.resolution__laptop__m {
        margin: 0;
      }
    }
  }

  .readMoreContainer {
    margin-top: 1rem;
    text-align: center;
    color: colors.$color-white;
    background-color: colors.$color-white;
    font-size: 1rem;
    padding: 0.5rem 0;
    width: 7rem;

    @include responsives.resolution__laptop__m {
      font-size: 0.8rem;
      width: 6rem;
    }

    a {
      color: colors.$brand-dark;
    }
  }
}
