@use "@scss/responsives" as responsives;

.iframe {
  width: 100%;
  height: 42.188rem;

  @include responsives.resolution__laptop__m {
    height: 34rem;
  }

  @include responsives.resolution__laptop__s {
    height: 25rem;
  }

  @include responsives.resolution__tablet {
    height: 17rem;
  }
}
