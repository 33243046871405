@use "@scss/mixins" as mixins;
@use "@scss/responsives" as responsives;

.container {
  @include mixins.flex_container(row, center, center);
  width: 100%;
  height: 30rem !important;
  margin-top: 4rem;

  @include responsives.resolution__laptop__m {
    padding: 0 1.5rem;
    margin-top: 3rem;
    height: 25rem !important;
  }

  > div {
    width: 100%;
  }
}
