@use "@scss/colors" as colors;
@use "@scss/mixins" as mixins;
@use "@scss/variables" as variables;
@use "@scss/responsives" as responsives;

.container {
  width: 100%;

  > div {
    a:nth-child(2),
    a:nth-child(3) {
      transform: translate(-50%, 365%);
      top: 0;
      border-radius: 3rem;
      background-color: colors.$brand-dark;
      height: 5rem;
      width: 5rem;
      opacity: 1;

      @include responsives.resolution__laptop__m {
        transform: translate(-50%, 280%);
      }

      @include responsives.resolution__laptop__s {
        transform: translate(-50%, 370%);
        height: 3rem;
        width: 3rem;

        span {
          height: 1.7rem;
          width: 1.7rem;
        }
      }

      @include responsives.resolution__tablet {
        transform: translate(-50%, 240%);
      }
    }

    a:nth-child(2) {
      left: 7.5%;
    }

    a:nth-child(3) {
      left: 92.5%;
    }
  }
}

.weeklyCalendar {
  @include mixins.flex_container(row, center, center);
  background-color: #272730;
  height: 42.188rem;
  overflow: hidden;

  @include responsives.resolution__laptop__m {
    height: 34rem;
  }

  @include responsives.resolution__laptop__s {
    height: 25rem;
  }

  @include responsives.resolution__tablet {
    height: 17rem;
  }

  > img {
    height: 100%;

    @include responsives.resolution__mobile {
      height: auto;
      width: 100%;
    }
  }
}
