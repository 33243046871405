@use "@scss/colors" as colors;
@use "@scss/responsives" as responsives;

.container {
  color: colors.$brand-dark;
  margin: 0;
  margin-bottom: 3rem;
  font-size: 1.25rem;
  text-align: left;

  .body {
    h1,
    h2 {
      margin-top: 5rem !important;
      margin-bottom: 2rem !important;
    }

    h3,
    h4 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
    }

    h5 {
      margin-top: 2rem !important;
      margin-bottom: 1rem !important;
    }

    iframe {
      width: 100%;
    }

    a {
      color: colors.$brand-dark;
      text-decoration: underline colors.$brand-dark !important;
      cursor: pointer;
    }
  }

  img {
    margin: 1rem 0;
    max-width: 100%;
  }

  @include responsives.resolution__tablet {
    font-size: 0.9rem;

    img {
      max-width: auto;
      max-width: 25rem;
    }
  }

  @include responsives.resolution__mobile {
    img {
      max-width: 21rem;
    }
  }
}
