@use "@scss/colors" as colors;
@use "@scss/mixins" as mixins;
@use "@scss/responsives" as responsives;

.container {
  @include mixins.flex_container(row, center, center);
  width: 100%;
  position: relative;
  height: 35rem;
  background-color: colors.$brand-dark;

  @include responsives.resolution__laptop__m {
    @include mixins.flex_container(column, center, center);
    height: auto;
  }

  .imageContainer {
    width: 100%;
    height: 35rem;

    @include mixins.flex_container(row, center, center);
    background-color: colors.$brand-dark;

    @include responsives.resolution__laptop__m {
      height: 25rem;
      overflow: hidden;
    }

    .image {
      width: 100%;
      height: 100%;
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .postDescription {
    @include mixins.flex_container(column, flex-end, center);
    position: absolute;
    z-index: 1;
    width: 25rem;
    height: 100%;
    transform: translate(-110%, 50%);
    left: 50%;
    bottom: 50%;
    padding-bottom: 2rem;

    @include responsives.resolution__laptop__m {
      @include mixins.flex_container(column, center, center);
      height: auto;
      width: 100%;
      position: static;
      transform: none;
      padding-bottom: 0;
    }

    > div {
      width: 100%;
      padding: 1.5rem;
      background-color: colors.$brand-dark;

      h4 {
        margin-bottom: 1.5rem;
        color: colors.$color-white;

        @include responsives.resolution__laptop__m {
          margin-bottom: 1rem;
        }
      }

      p {
        font-size: 1.25rem;

        @include responsives.resolution__laptop__m {
          font-size: 0.8rem;
        }
      }

      div {
        text-align: center;
        color: colors.$color-white;
        background-color: colors.$color-white;
        font-size: 1rem;
        padding: 0.5rem 0;
        width: 7rem;

        @include responsives.resolution__laptop__m {
          font-size: 0.8rem;
          width: 6rem;
        }

        a {
          color: colors.$brand-dark;
        }
      }
    }
  }
}
