@use "@scss/colors" as colors;
@use "@scss/mixins" as mixins;
@use "@scss/variables" as variables;
@use "@scss/fonts" as fonts;
@use "@scss/responsives" as responsives;

.container {
  @include mixins.flex_container(column, space-between, center);
  width: variables.$web-width;

  @include responsives.resolution__laptop__m {
    width: 100%;
  }

  .vault {
    @include mixins.flex_container(row, space-between, center);
    width: 100%;

    @include responsives.resolution__laptop__m {
      @include mixins.flex_container(column, center, center);
    }

    .artInformation {
      @include mixins.flex_container(column, space-between, flex-start);
      padding: 1.5rem;
      padding-left: 0;
      height: 100%;
      width: 100%;
      margin-right: 10rem;

      @include responsives.resolution__laptop__m {
        margin-right: 0;
        padding-left: 1.5rem;
      }

      h5 {
        font-family: fonts.$font-oswald;
        font-size: 2rem;
      }

      h5,
      hr,
      p {
        margin-bottom: 1rem;
      }

      .sign {
        font-size: 1.2rem;
        color: colors.$color-gray-5;
        font-family: fonts.$font-oswald-light;
        font-weight: medium;
      }

      .note {
        font-size: 1.1rem;

        @include responsives.resolution__laptop__m {
          font-size: 1rem;
        }
      }

      hr {
        margin-top: 0;
        width: 100%;
        height: 0.05rem;
        border: none;
        background-color: colors.$color-gray-5;
      }

      .sign {
        margin-bottom: 1rem;
      }

      .link {
        text-align: center;
        color: colors.$brand-dark;
        background-color: colors.$brand-dark;
        font-size: 1rem;
        padding: 0.5rem 0;
        width: 7rem;

        @include responsives.resolution__laptop__m {
          font-size: 0.8rem;
          width: 6rem;
        }

        a {
          color: colors.$color-white;
        }
      }
    }

    .imageContainer {
      @include mixins.flex_container(row, center, center);
      height: 20rem;
      max-width: 41rem;

      @include responsives.resolution__laptop__m {
        max-width: auto;
        height: auto;
        width: 100%;
      }

      img {
        height: 100%;

        @include responsives.resolution__laptop__m {
          width: 100%;
        }
      }
    }
  }
}
