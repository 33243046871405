@use "@scss/colors" as colors;
@use "@scss/fonts" as fonts;
@use "@scss/variables" as variables;
@use "@scss/responsives" as responsives;

@font-face {
  font-family: "Oswald-Light";
  src: local("Oswald-Light"),
    url("assets/fonts/Oswald-Light.ttf") format("truetype");
  font-weight: regular;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-Regular"),
    url("assets/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: regular;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Stolzl";
  src: local("Stolzl-Bold"),
    url("assets/fonts/Stolzl-Bold.ttf") format("truetype");
  font-weight: regular;
  font-style: normal;
  font-display: swap;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: fonts.$font-oswald;
}

a {
  text-decoration: none !important;
}

body,
html {
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4 {
  font-family: fonts.$font-stolzl;
  font-style: normal;
  text-transform: uppercase;
  margin: 0 !important;
  color: colors.$brand-dark;
}

h1 {
  font-size: variables.$h1-size !important;

  @include responsives.resolution__laptop__s {
    font-size: variables.$h1-size-mobile !important;
  }
}

h2 {
  font-size: variables.$h2-size !important;

  @include responsives.resolution__laptop__s {
    font-size: variables.$h2-size-mobile !important;
  }
}

h3 {
  font-size: variables.$h3-size !important;

  @include responsives.resolution__laptop__s {
    font-size: variables.$h3-size-mobile !important;
  }
}

h4 {
  font-size: variables.$h4-size !important;

  @include responsives.resolution__laptop__s {
    font-size: variables.$h4-size-mobile !important;
  }
}

span,
p {
  font-family: fonts.$font-montserrat;
  font-weight: medium;
}

a {
  font-family: fonts.$font-oswald;
  font-weight: medium;
}
